// Entry point for the build script in your package.json
// NPM libraries
import "@hotwired/turbo-rails";
import * as bootstrap from "bootstrap";

// NPMライブラリだけれどなぜかimportで含まれなかった
// import "@jarstone/dselect/dist/js/dselect.js";

// Locals
import "./controllers";

import "./shortcut"

import "trix"
import "@rails/actiontext"

// リダイレクトさせる
// 例： class ZaicoPackingSlipExportsController で利用
document.addEventListener("turbo:before-stream-render", (event) => {
  const streamElement = event.target;
  if (streamElement.action === "redirect") {
    event.preventDefault(); // Turboによる通常のレンダリングをキャンセル
    const template = streamElement.templateContent;
    const url = template.textContent.trim();
    Turbo.visit(url);
  }
});