import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="auto-submit"
export default class extends Controller {
  connect() {
    // 値変更時に自動送信されるinput要素
    const input = this.element;
    // inputを持っているフォーム要素
    const form = input.form;
    input.onchange = function() {
      if (input.value) {
        form.submit();
        input.value = '';
      }
    };
  }
}
